import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';  
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';



@NgModule({
    imports: [
        VerticalLayout1Module,
        BrowserModule,
        CommonModule


    ],
    exports: [
        VerticalLayout1Module,


    ]
})
export class LayoutModule
{
}
