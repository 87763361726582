import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'Applicazioni',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'Dashboards',
                type: 'item',
                icon: 'dashboard',
                url: 'dashboard'

            },
            {
                id: 'users',
                title: 'Clienti',
                translate: 'Clienti',
                type: 'item',
                icon: 'account_box',
                url: '/apps/users'
          },
          {
            id: 'renters',
            title: 'Noleggiatori',
            translate: 'Noleggiatori',
            type: 'item',
            icon: 'account_box',
            url: '/apps/renters'
          },
          {
            id: 'orders',
            title: 'Ordini',
            translate: 'Ordini',
            type: 'item',
            icon: 'move_to_inbox',
            url: '/apps/orders'
          },
          {
            id: 'facture',
            title: 'Fatture',
            translate: 'Fatture',
            type: 'item',
            icon: 'ballot',
            url: 'https://www.fattura24.it'
          },
        //   {
        //     id: 'vettori',
        //     title: 'Vettori',
        //     translate: 'Vettori',
        //     type: 'item',
        //     icon: 'account_box',
        //     url: '/apps/vettori'
        //   },
          
          //  {
          //      id: 'companies',
          //      title: 'Aziende',
          //      translate: 'Aziende',
          //      type: 'item',
          //      icon: 'business',
          //      url: '/apps/companies'
          //  },
          //  {
          //      id: 'indices',
          //      title: 'Indici',
          //      translate: 'Indici',
          //      type: 'item',
          //      icon: 'receipt',
          //      url: '/apps/indices'
          //}
        ]
    }];

export const navigationUser: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'Applicazioni',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'Dashboards',
                type: 'item',
                icon: 'dashboard',
                url: 'dashboard'

            },
            {
              id: 'places',
              title: 'Sedi',
              translate: 'Sedi',
              type: 'item',
              icon: 'home',
              url: '/apps/places'
            },
            {
              id: 'listini',
              title: 'Listino Prezzi',
              translate: 'Listino Prezzi',
              type: 'item',
              icon: 'monetization_on',
              url: '/apps/prices'
            },
            {
                id: 'services',
                title: 'Servizi',
                translate: 'Servizi',
                type: 'item',
                icon: 'developer_board',
                url: '/apps/services/serv/0'
              },
              {
                id: 'orders',
                title: 'Ordini',
                translate: 'Ordini',
                type: 'item',
                icon: 'move_to_inbox',
                url: '/apps/orders'
              }
              // {
              //   id: 'search',
              //   title: 'Ricerca',
              //   translate: 'Ricerca',
              //   type: 'item',
              //   icon: 'account_box',
              //   url: '/apps/search'
              // }
            ]
    }];



                   
