import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
    title: 'errore';
    content: 'errore generico';
}

@Component({
    selector: 'dialog-content-dialog',
    templateUrl: './alertDialog.component.html',
})
export class AlertComponentDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}




