import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { CookieModule } from 'ngx-cookie';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RestService } from './rest.service';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';
import { UsersFormComponent } from 'app/main/apps/users/users-form/users-form.component';
import { OpenLinkInNewWindowDirective } from './openLink';
import { AlertComponent } from './main/alert/alert.component';
import { AlertComponentDialog } from './main/alert/alertDialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const appRoutes: Routes = [
    {
          path: 'apps',
         //loadChildren: () => import('main/apps/apps.module').then(m => m.AppModule)
          loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path        : 'pages',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    
    {
        path      : 'dashboard',
        redirectTo: 'apps/dashboards/project'
    },
    {
        path      : 'login',
        redirectTo: 'pages/site/workwithus'
    }, 
    {
        path      : 'qrcode',
        redirectTo: 'pages/qrcode'
    },
    {
        path      : 'paypalerror',
        redirectTo: 'pages/paypalerror'
    },
    {
        path      : 'paypalaccepted',
        redirectTo: 'pages/paypalaccepted'
    },
    {
        path: '',
        redirectTo: 'pages/site/main',
        //redirectTo: 'pages/qrcode',
       // redirectTo: 'pages/site/main',
       // redirectTo: 'pages/auth/login',
        pathMatch: 'full'
  },
  { 
      path: 'apps/facture', 
      component: OpenLinkInNewWindowDirective 
    },
    { path: '**', component: Error404Module }
    //  {
    //    path: '**',
    //    redirectTo: 'apps/dashboards/analytics'
    //},
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent,
        UsersFormComponent,
        AlertComponent,
        AlertComponentDialog,
        OpenLinkInNewWindowDirective

    ],  
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { enableTracing: true, relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),
        CookieModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        Error404Module,
        LayoutModule,
        AppStoreModule,
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD8RpbVlpfwwQq0RXbuc2p4lO-VZtstVyw'
        }),
        AgmDirectionModule,
        AgmSnazzyInfoWindowModule
    ],
    providers: [
        RestService,
        { provide: MAT_DATE_LOCALE, useValue: 'it' }, //you can change useValue
       // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    bootstrap   : [
        AppComponent
    ],
    entryComponents: [
        UsersFormComponent,
        AlertComponent,
        AlertComponentDialog
    ]
})
export class AppModule
{
}
