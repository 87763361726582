import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UserLogged } from 'app/user.logged';
import { navigation } from 'app/navigation/navigation';
import { UserloggedFormComponent } from '../toolbar/userlogged-form/userlogged-form.component';
import { Nation } from 'app/main/apps/renters/renter.model';

declare var require: any

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;
    dialogRef: any;

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    @Input()
    userLogged: any;
    Nations: Array<Nation>;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public _matDialog: MatDialog,
        private router: Router
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;
        this.Nations = new Array<Nation>();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

        this._fuseSidebarService.onNationsChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(nations => {
          for (let nat of nations) {
            
              this.Nations.push(nat); 
          }
          
        });
        this._fuseSidebarService.onUserReload
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(renter => {
            if (renter.Id != null)
            {
                UserLogged.setValuesOnlyRenter(renter);
                this.userLogged = UserLogged;
                if (this.userLogged != null && this.userLogged.UserImage == '') {
                this.userLogged.UserImage = "assets/images/avatars/profile.jpg";
            }
           
        }
        });
        this.userLogged = UserLogged;
        if (this.userLogged != null && this.userLogged.UserImage == '') {
            this.userLogged.UserImage = "assets/images/avatars/profile.jpg";
        }
        this._fuseSidebarService.getAllNations();
          //this._fuseSidebarService.onUserReload
          //  .pipe(takeUntil(this._unsubscribeAll))
          //  .subscribe(user => {
          //      UserLogged.setValues(user);
          //      this.userLogged = UserLogged;
          //  });
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    // editProfile(): void {
    //     this.dialogRef = this._matDialog.open(RenterFormComponent, {
    //         panelClass: 'contact-form-dialog',
    //         data: {
    //             fromBase :true,
    //             user: this.userLogged,
    //             action: 'edit'
    //         }
    //     });

    //     this.dialogRef.afterClosed()
    //         .subscribe(response => {
    //             if (!response) {
    //                 return;
    //             }
                
    //             const actionType: string = response[0];
    //             const formData: FormGroup = response[1];
    //             //const fileToUplad: File = response[2];
    //             switch (actionType) {
    //                 /**
    //                  * Save
    //                  */
    //                 case 'save':
    //                     //alert('file to upload: ' + fileToUplad);
    //                     this._fuseSidebarService.updateUser(formData.getRawValue(), response[2]); //formData.getRawValue()

    //                     break;
    //                 /**
    //                  * Delete
    //                  */
                    
    //             }
    //         });
    // }
    editProfile(): void {
        //alert('userRoles: ' +JSON.stringify(this.userRoles));
        this.dialogRef = this._matDialog.open(UserloggedFormComponent, {
          panelClass: 'contact-form-dialog',
          data: {
            fromBase: false,
            Nations :this.Nations, //this.Nations,
            isAdmin:false,
            Renter: this.userLogged,
            action: 'edit'
          }
        });
    
        this.dialogRef.afterClosed()
          .subscribe(response => {
            if (!response) {
              return;
            }
            const actionType: string = response[0];
            const formData: FormGroup = response[1];
            switch (actionType) {
              /**
               * Save
               */
              case 'save':
    
                this._fuseSidebarService.updateRenter(formData.getRawValue(), response[2]);
    
                break;
              /**
               * Delete
               */
            
            }
          });
      }
    logout(): void {
        this.router.navigate(['/']);
    }
}
