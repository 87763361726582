import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertComponentDialog } from './alertDialog.component';



@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    content: string;
    title: string;

    constructor(public dialog: MatDialog, public callback:any=null) { }

    openDialog(title, content): void {
        this.title = title;
        this.content = content;
        const dialogRef = this.dialog.open(AlertComponentDialog, {
            width: '350px',
            data: {
                title: this.title,
                content: this.content
            }
           
            //title: { this.title: title, content: content }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (this.callback != null)
            {
              this.callback(true);
            }
          
           // this.animal = result;
        })
    }



  ngOnInit() {
  }

}





