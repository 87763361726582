import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseSidebarComponent } from './sidebar.component';
import { RestService } from 'app/rest.service';
import { Nation } from 'app/main/apps/renters/renter.model';

@Injectable({
    providedIn: 'root'
})
export class FuseSidebarService
{
    // Private
    onNationsChanged: BehaviorSubject<any>;
    nations:Nation[];
    private _registry: { [key: string]: FuseSidebarComponent } = {};
    onUserReload: BehaviorSubject<any>;
   // private rest: RestService
    /**
     * Constructor
     */
    constructor(private rest: RestService)
    {
        this.onUserReload = new BehaviorSubject([]);
        this.onNationsChanged = new BehaviorSubject([]);
    }

    /**
     * Add the sidebar to the registry
     *
     * @param key
     * @param sidebar
     */
    register(key, sidebar): void
    {
        // Check if the key already being used
        if ( this._registry[key] )
        {
            console.error(`The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`);

            return;
        }

        // Add to the registry
        this._registry[key] = sidebar;
    }

    /**
     * Remove the sidebar from the registry
     *
     * @param key
     */
    unregister(key): void
    {
        // Check if the sidebar exists
        if ( !this._registry[key] )
        {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);
        }

        // Unregister the sidebar
        delete this._registry[key];
    }

    /**
     * Return the sidebar with the given key
     *
     * @param key
     * @returns {FuseSidebarComponent}
     */
    getSidebar(key): FuseSidebarComponent
    {
        // Check if the sidebar exists
        if ( !this._registry[key] )
        {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Return the sidebar
        return this._registry[key];
    }
    updateRenter(Renter, fileToUpload): Promise<any> {
        return new Promise((resolve, reject) => {
          this.rest.SaveRenter(Renter).subscribe((response: any) => {
    
            if (response.Error == '') {
              if (fileToUpload != null) {
                fileToUpload.append('IdRenter', response.Data.Id);
                this.rest.SaveAvatarRenter(fileToUpload).subscribe((responseAvatar: any) => {
                  if (responseAvatar != null) {
                    this.onUserReload.next(responseAvatar.Data);
                    resolve(response.Data);
                  }
                }, reject);
              }
              else {
                this.onUserReload.next(response.Data);
                resolve(response.Data);
              }
    
    
            }
            
    
          }, reject);
        });
      }
      getAllNations(): Promise<any>
      {
        return new Promise((resolve, reject) => {
            this.rest.getAllNations().subscribe((response: any) => {
              
                 if (response.Error == '' )
                 {
                    //this.RenterOffices = response.Data;
                    this.nations = response.Data;
    
                    this.onNationsChanged.next(this.nations);
                    
                    resolve(response);
                 }
                 else
                 {
                    //this.onRenterMessage.next(response.Error);
                    //throw new Error(response.Error);
                 }
               
              }, reject);
          
            });
          
         
      }
}
