export class UsersFakeDb
{
    public static users = [
        {
            'Id'      : '1',
            'Nome'    : 'Marco',
            'Cognome': 'Bazzoli',
            'Login': 'marco',
            'Password': 'marco',
            'Email': 'marco@marco.it',
            'IdTipoUtente': '1'
        },
        {
            'Id': '2',
            'Nome': 'Pippo',
            'Cognome': 'De pippis',
            'Login': 'pippo',
            'Password': 'pippo',
            'Email': 'pippo@pippo.it',
            'IdTipoUtente': '2'
        },

    ];

    public static user2 = [
        {
            'Id'              : '1',
            'Nome'            : 'John Doe'
        }
    ];
}
