import { FuseUtils } from '@fuse/utils';
import { UserLogged } from 'app/user.logged';

export class User {
    Id: number;
  Name: string;
  Surename: string;
  Email: string;
  Password: string;
  Address: string;
  AddressNumber: string;
  CAP: string;
  City: string;
  Province: string;
  Disabled: boolean;
  UserActivated: boolean;
  RegistrationDate: string;
  PhoneNumber: string;
  Avatar: string;
  IdNation: number;
  IdZone: number;
  CF: string;
  VAT: string;
  PEC: string;
  CompanyName: string;
  IsCompany: boolean;

    /**
     * Constructor
     *
     * @param contact
     */
     //constructor() {}
    constructor(user) {
        {
        this.Id = user.Id || '0';
        this.Name = user.Name || '';
        this.Surename = user.Surename || '';
           // this.Avatar = 'assets/images/avatars/profile.jpg';
        this.Address = user.Address || '';
        this.AddressNumber = user.AddressNumber || '';
        this.Password = user.Password || '';
        this.Email = user.Email || '';
        this.CAP = user.CAP || '';
        this.City = user.City || '';
        this.Province = user.Province || '';
        this.Disabled = user.Disabled || false;
        this.UserActivated = user.UserActivated || false;
        this.RegistrationDate = user.RegistrationDate || '';
        this.PhoneNumber = user.PhoneNumber || '';
        this.Avatar = user.Avatar || '';
        this.IdZone = user.IdZone || '0';
        this.IdNation = user.IdNation || '0';
        this.CF = user.CF || '';
        this.VAT = user.VAT || '';
        this.PEC = user.PEC || '';
        this.CompanyName = user.CompanyName || '';
        this.IsCompany = user.IsCompany || false;

        }
   }
  }
    export class UsersListViewModel {
      // constructor(vettori,lenght) {
      //   this.Vectors = vettori || null;
      //   this.Lenght = lenght || 0;
      // }
      constructor() {
        
      }
        Users: User[];
        Lenght: number;
    }
    
