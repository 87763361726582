import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Nation, Renter } from 'app/main/apps/renters/renter.model';

@Component({
  selector: 'app-userlogged-form',
  templateUrl: './userlogged-form.component.html',
  styleUrls: ['./userlogged-form.component.scss']
})
export class UserloggedFormComponent
{

    public action: string;
    renter: any;
    fromBase: boolean;
    renterForm: FormGroup;
    dialogTitle: string;
    filenameSelected: string;
   // userRoles: UserRole[];
    filebase64: string;
    fileToUpload: File = null;
    nations :Nation[];
    isAdmin:boolean = false;
    /**
     * Constructor
     *
     * @param {MatDialogRef<VettoriFormComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UserloggedFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
       // this.userRoles = _data.userroles;
        this.fromBase = _data.fromBase;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit';
            this.renter = _data.Renter;
            this.nations = _data.Nations;
            this.isAdmin = _data.isAdmin;
            if ( this.renter.UserImage == null ||  this.renter.UserImage == '') {
                this.renter.UserImage = 'assets/images/avatars/profile.jpg';
              }
            //alert('selected user' + JSON.stringify(_data.user));
        }
        else {
            this.dialogTitle = 'Nuovo';
            this.renter = new Renter({});
        }

        this.renterForm = this.createrenterForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createrenterForm(): FormGroup {
        return this._formBuilder.group({
            Id: [this.renter.IdRenter],
            Name: [this.renter.Name],
            Surename: [this.renter.Surename],
            Password: [this.renter.Password],
            Email: [this.renter.Email],
            Avatar: [this.renter.UserImage],
            Address : [this.renter.Address],
            AddressNumber : [this.renter.AddressNumber],
            CAP  : [this.renter.CAP],
            City : [this.renter.City],
            Province : [this.renter.Province],
            Disabled : [this.renter.Disabled],
            UserActivated : [this.renter.UserActivated],
            RegistrationDate : [this.renter.RegistrationDate],
            PhoneNumber : [this.renter.PhoneNumber],
            IdNation : [this.renter.IdNation],
            IdZone : [this.renter.IdZone],
            CompanyName  : [this.renter.CompanyName],
            VAT : [this.renter.VAT],
            PEC : [this.renter.PEC],
            IBAN : [this.renter.IBAN],
            Ncc : [this.renter.Ncc],
            Ebike : [this.renter.Ebike],
            Rafting : [this.renter.Rafting],
            Shuttle : [this.renter.Shuttle],
            Horsing : [this.renter.Horsing],
            Ski : [this.renter.Ski]
        });
    }


    onFileInput(files: FileList) {
        //const fileList: FileList = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            //this.userForm.get('Avatar').setValue(file);
            this.fileToUpload = file;
            this.filenameSelected = file.name;
        }
        
       // 
    }
    closeForm(type, groupData) {
        var uploadData = null;
        if (this.fileToUpload != null)
        {
            uploadData = new FormData();
            uploadData.append('file', this.fileToUpload);
        }
        
        //alert('groupData:' + groupData);
        //uploadData.append('User', groupData);
        //this.userForm.FileUpload = this.fileToUpload;
        this.matDialogRef.close([type, groupData, uploadData]);
    }

}

