import { FuseUtils } from '@fuse/utils';

export abstract class UserLogged {

    
    public static Id: number;
    public static Name: string;
    public static Surename: string;
    public static Email: string;
    public static Password: string;
    public static Address: string;
    public static AddressNumber: string;
    public static CAP: string;
    public static City: string;
    public static Province: string;
    public static Disabled: string;
    public static UserActivated: string;
    public static RegistrationDate: Date;
    public static PhoneNumber: string;
    public static UserImage: string;
    public static IdNation: number;
    public static IdZone: number;
    public static IdRenter: number;
    public static IsAdmin:boolean;
    public static Fee:number;
    public static CompanyName: string;
    public static VAT: string;
    public static PEC: string;
    public static IBAN: string;
    public static Ncc:boolean; 
    public static Ebike:boolean;
    public static Rafting:boolean;
    public static Shuttle:boolean;
    public static Horsing:boolean;
    public static Ski:boolean;
  
    public static setValues(userlogged) {
        {
            this.Id = userlogged.Id || 0;
            this.Name = userlogged.Name || '';
            this.Surename = userlogged.Surename || '';
            this.Address = userlogged.Address || '';
            this.AddressNumber = userlogged.AddressNumber || '';
            this.Password = userlogged.Password || '';
            this.Email = userlogged.Email || '';
            this.CAP = userlogged.CAP || '';
            this.City = userlogged.City || '';
            this.Province = userlogged.Province || '';
            this.Disabled = userlogged.Disabled || false;
            this.UserActivated = userlogged.UserActivated || false;
            this.RegistrationDate = userlogged.RegistrationDate || new Date();
            this.PhoneNumber = userlogged.PhoneNumber || '';
            this.UserImage = userlogged.UserImage || '';
            this.IdZone = userlogged.IdZone || 0;
            this.IdNation = userlogged.IdNation || 0;
            this.IsAdmin = userlogged.IsAdmin || false;
            this.IdRenter = userlogged.IdRenter || 0;
            this.Fee = 0.1;
            this.CompanyName = userlogged.CompanyName || '';
            this.VAT = userlogged.VAT || '';
            this.PEC = userlogged.PEC || '';
            this.IBAN = userlogged.IBAN || '';
            this.Ncc = userlogged.Ncc || false;
            this.Ebike = userlogged.Ebike || false;
            this.Rafting = userlogged.Rafting || false;
            this.Shuttle = userlogged.Shuttle || false;
            this.Horsing = userlogged.Horsing || false;
            this.Ski = userlogged.Ski || false;
        }
    }
    public static setValuesOnlyRenter(renter) {
        {
            this.Id = renter.IdUser || 0;
            this.Name = renter.User.Name || '';
            this.Surename = renter.User.Surename || '';
            this.Address = renter.User.Address || '';
            this.AddressNumber = renter.User.AddressNumber || '';
            this.Password = renter.User.Password || '';
            this.Email = renter.User.Email || '';
            this.CAP = renter.User.CAP || '';
            this.City = renter.User.City || '';
            this.Province = renter.User.Province || '';
            this.Disabled = renter.User.Disabled || false;
            this.UserActivated = renter.User.UserActivated || false;
            this.RegistrationDate = renter.User.RegistrationDate || new Date();
            this.PhoneNumber = renter.User.PhoneNumber || '';
            this.UserImage = renter.User.Avatar || '';
            this.IdZone = renter.User.IdZone || 0;
            this.IdNation = renter.User.IdNation || 0;
            this.IsAdmin =  false;
            this.IdRenter = renter.Id || 0;
            this.Fee = 0.1;
            this.CompanyName = renter.CompanyName || '';
            this.VAT = renter.VAT || '';
            this.PEC = renter.PEC || '';
            this.IBAN = renter.IBAN || '';
            this.Ncc = renter.Ncc || false;
            this.Ebike = renter.Ebike || false;
            this.Rafting = renter.Rafting || false;
            this.Shuttle = renter.Shuttle || false;
            this.Horsing = renter.Horsing || false;
            this.Ski = renter.Ski || false;
        }
    }
}

