import { FuseUtils } from '@fuse/utils';
import { UserLogged } from 'app/user.logged';
export class Renter {
  Id: number;
  Name: string;
  Surename: string;
  Email: string;
  Password: string;
  Address: string;
  AddressNumber: string;
  CAP: string;
  City: string;
  Province: string;
  Disabled: boolean;
  UserActivated: string;
  RegistrationDate: Date;
  PhoneNumber: string;
  UserImage: string;
  IdNation: number;
  IdZone: number;
  CompanyName: string;
  VAT: string;
  PEC: string;
  IBAN: string;

  Ncc:boolean; 
  Ebike:boolean;
  Rafting:boolean;
  Shuttle:boolean;
  Horsing:boolean;
  Ski:boolean;
  Eticket:boolean;
    /**
     * Constructor
     *
     * @param contact
     */
    constructor(renter) {
        {
        this.Id = renter.Id || 0;
        this.Name = renter.Name || '';
        this.Surename = renter.Surename || '';
           // this.Avatar = 'assets/images/avatars/profile.jpg';
        this.Address = renter.Address || '';
        this.AddressNumber = renter.AddressNumber || '';
        this.Password = renter.Password || '';
        this.Email = renter.Email || '';
        this.CAP = renter.CAP || '';
        this.City = renter.City || '';
        this.Province = renter.Province || '';
        this.Disabled = renter.Disabled || false;
        this.UserActivated = renter.UserActivated || false;
        this.RegistrationDate = renter.RegistrationDate || new Date();
        this.PhoneNumber = renter.PhoneNumber || '';
        this.UserImage = renter.UserImage || '';
        this.IdZone = renter.IdZone || 0;
        this.IdNation = renter.IdNation || 0;
        this.CompanyName = renter.CompanyName || '';
       
        this.VAT = renter.VAT || '';
        this.PEC = renter.PEC || '';
        this.IBAN = renter.IBAN || '';
        this.Ncc = renter.Ncc || false;
        this.Ebike = renter.Ebike || false;
        this.Rafting = renter.Rafting || false;
        this.Shuttle = renter.Shuttle || false;
        this.Horsing = renter.Horsing || false;
        this.Ski = renter.Ski || false;
        this.Eticket = renter.Eticket || false;
        }
    }
}

export class RentersViewModel {
  // constructor(vettori,lenght) {
  //   this.Vectors = vettori || null;
  //   this.Lenght = lenght || 0;
  // }
  constructor() {
    
  }
    Renters: Renter[];
    Lenght: number;
}

export class Nation {
  Id: number;
  Description: string;
 
    /**
     * Constructor
     *
     * @param contact
     */
    constructor(nation) {
        {
        this.Id = nation.Id || 0;
        this.Description = nation.Name || '';
        }
    }
}
