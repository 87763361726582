import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'app/main/apps/users/user.model';

@Component({
  selector: 'users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent
{

    action: string;
    user: User;
    fromBase: boolean;
    userForm: FormGroup;
    dialogTitle: string;
   // userRoles: UserRole[];
    filebase64: string;
    fileToUpload: File = null;

    /**
     * Constructor
     *
     * @param {MatDialogRef<UsersFormComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UsersFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
       // this.userRoles = _data.userroles;
        this.fromBase = _data.fromBase;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit';
            this.user = _data.user;
            //alert('selected user' + JSON.stringify(_data.user));
        }
        else {
            this.dialogTitle = 'Nuovo';
            this.user = new User({});
        }

        this.userForm = this.createUserForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createUserForm(): FormGroup {
        return this._formBuilder.group({
            Id: [this.user.Id],
            Name: [this.user.Name],
            Surename: [this.user.Surename],
            Password: [this.user.Password],
            Email: [this.user.Email],
            Avatar: [this.user.Avatar],

            Address : [this.user.Address],
            AddressNumber : [this.user.AddressNumber],
            CAP  : [this.user.CAP],
            City : [this.user.City],
            Province : [this.user.Province],
            Disabled : [this.user.Disabled],
            UserActivated : [this.user.UserActivated],
            RegistrationDate : [this.user.RegistrationDate],
            PhoneNumber : [this.user.PhoneNumber],
            IdNation : [this.user.IdNation],
            IdZone : [this.user.IdZone],
            CompanyName  : [this.user.CompanyName],
            VAT : [this.user.VAT],
            PEC : [this.user.PEC],
            CF : [this.user.CF],
            IsCompany :[this.user.IsCompany]
        });
    }
    checkboxChange( event ){
       // let name = event.source.name;
        this.user.IsCompany = event.checked ;
     }

    onFileInput(files: FileList) {
        //const fileList: FileList = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            //this.userForm.get('Avatar').setValue(file);
            this.fileToUpload = file;
        }
        
       // 
    }

    closeForm(type, groupData) {
        const uploadData = new FormData();
        uploadData.append('file', this.fileToUpload);
        
        //alert('groupData:' + groupData);
        //uploadData.append('User', groupData);
        //this.userForm.FileUpload = this.fileToUpload;
    
        this.matDialogRef.close([type, groupData, uploadData]);
    }

}








