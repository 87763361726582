import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { UserLogged } from './user.logged';
import { saveAs } from 'file-saver';
import { Response } from './response.model';

//l'endpoint giusto si trova sul proxy, questo e l'indirizzo locale
// NB: se vado direttamente sull'endpoint corretto, il browser mi restituisce una COARS exception
//perchè non è permesso la chiamata backend su un differente dominio
const endpoint = '/Web/'; 
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Methods': 'POST',
        "Access-Control-Allow-Headers": "Content-Type,Access-Control-Allow-Headers,Authorization",
        'Access-Control-Allow-Credentials': 'true'

    })
};

const httpMultipartOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        //'Access-Control-Allow-Methods': 'POST',
       // "Access-Control-Allow-Headers": "Content-Type,Access-Control-Allow-Headers,Authorization",
        'Access-Control-Allow-Credentials': 'true'

    })
};
//constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
//  http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
//    this.forecasts = result;
//  }, error => //console.error(error));
//}
@Injectable()
export class RestService {

    constructor(private http: HttpClient) { tap(_ => console.log("Rest service started")) }

    // private extractData(res: Response) {
    //     let body = res;
    //     return body || {};
    // }

   //#region Users
    getCredentials(jsonString): Observable<any> {
        //console.log(`Credentials token=${JSON.stringify(jsonString)}`)
        let resp = new Response('getCredentials');
        return this.http.post<any>(endpoint + 'getlogin', jsonString, httpOptions).pipe(
          
            tap((response) => {
                resp =response;
                //console.log(`Response credential received =${JSON.stringify(response)}`)
            }),
            catchError(this.handleError(resp))
        );
    }
    recoverPassword(jsonString): Observable<any> {
      //console.log(`Credentials token=${JSON.stringify(jsonString)}`)
      let resp = new Response('getCredentials');
      return this.http.post<any>(endpoint + 'recoverPassword', jsonString, httpOptions).pipe(
          tap((response) => {
              resp =response;
              //console.log(`Response credential received =${JSON.stringify(response)}`)
          }),
          catchError(this.handleError(resp))
      );
     }
     confirmUser(idUser): Observable<any> {
       
      //console.log(`Credentials token=${JSON.stringify(jsonString)}`)
      let resp = new Response('getCredentials');
      return this.http.get<any>(endpoint + 'confirmUser?idUser=' + idUser).pipe(
        
          tap((response) => {
              resp =response;
              //console.log(`Response credential received =${JSON.stringify(response)}`)
          }),
          catchError(this.handleError(resp))
      );
  }
    getUsers(pageNumber, pageSize,search): Observable<any> {
      let resp = new Response('getUsers');
      return this.http.get<any>(endpoint + 'users?pageNumber=' + pageNumber + '&pageSize=' + pageSize+ '&search=' + search).pipe(
        tap((response) => {
          resp = response;
          //console.log(`Renters received ${JSON.stringify(resp)}`)
        }),
        catchError(this.handleError(resp))
      );
    }
    SaveUser(user): Observable<any> {
        ////console.log(`Save token=${JSON.stringify(user)}`
        //console.log(`Ready To upload=${user}`);
        let resp = new Response('SaveUser');
        return this.http.post<any>(endpoint + 'saveuser', user, httpOptions).pipe(
            tap((response) => {
                resp =response;
                //console.log(`USER saved id=${JSON.stringify(resp)}`)
            }),
            catchError(this.handleError(resp))
        );
    }
    SaveAvatarUser(file): Observable<any> {
        ////console.log(`Save token=${JSON.stringify(user)}`
       // //console.log(`Ready To upload=${user}`)
       let resp = new Response('SaveAvatarUser');
        return this.http.post<any>(endpoint + 'saveavataruser', file, httpMultipartOptions).pipe(
            tap((user) => {
                //console.log(`USER saved id=${JSON.stringify(resp)}`)
            }),
            catchError(this.handleError(resp))
        );
    }
    DeleteUser(user): Observable<Response> {
        let resp = new Response('DeleteUser');
        return this.http.get<any>(endpoint + 'disableuser?Id=' + user.Id + '&isCompany=' + user.IsCompany, httpOptions).pipe(
            tap((result) => {
              
                resp = result
                //console.log(`USER delete response=${JSON.stringify(resp)}`)
            }),
            catchError(this.handleError(resp))
        );
    }

    getUserRoles(IdTipoUtenteLoggedUser): Observable<any> {
      let resp = new Response('getUserRoles');
        return this.http.get<any>(endpoint + 'getuserroles?IdTipoUtenteLoggedUser=' +IdTipoUtenteLoggedUser).pipe(
            tap((userroles) => {
                resp =userroles;
                //console.log(`userroles received ${JSON.stringify(resp)}`)
            }),
            catchError(this.handleError(resp))
        );
    }


   
  //#endregion
   //#region Renters
  getRenters(pageNumber, pageSize,search): Observable<any> {
    let resp = new Response('getRenters');
    return this.http.get<any>(endpoint + 'renters?pageNumber=' + pageNumber + '&pageSize=' + pageSize+ '&search=' + search).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renters received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }


  getRenterOffices(idRenter,isAdmin): Observable<any> {
    let resp = new Response('getRenterOffices');
    return this.http.get<any>(endpoint + 'renterOffices?idRenter=' + idRenter + "&isAdmin=" +isAdmin).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renter Offices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveRenterOffice(RenterOffice): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(RenterOffice)}`)
    let resp = new Response('SaveRenterOffice');
    return this.http.post<any>(endpoint + 'saveoffice', RenterOffice,httpOptions).pipe(
      tap((RenterOffice) => {
        resp = RenterOffice;
        //console.log(`Renter saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }


  DeleteRenterOffice(RenterOffice): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'deleteRenterOffice?officeId=' + RenterOffice.Id ).pipe(
      tap((response) => {
        resp = RenterOffice;
        //console.log(`Renter saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DuplicateRenterOffice(idRenterOffice): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'duplicateOffice?idRenterOffice=' + idRenterOffice ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveRenter(Renter): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
   // //console.log(`Ready To upload=${JSON.stringify(Renter)}`)
    let resp = new Response('SaveRenter');
    return this.http.post<any>(endpoint + 'saveRenter', Renter,httpOptions).pipe(
      tap((Renter) => {
        resp = Renter;
        //console.log(`Renter saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveAvatarRenter(file): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    // //console.log(`Ready To upload=${user}`)
    let resp = new Response('SaveAvatarRenter');
    return this.http.post<any>(endpoint + 'saveAvatarRenter', file, httpMultipartOptions).pipe(
      tap((Renter) => {
        resp = Renter;
        //console.log(`USER saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DeleteRenter(Renter): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'disableRenter?vectId=' + Renter.Id ).pipe(
      tap((response) => {
        resp = Renter;
        //console.log(`Renter saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
    // return this.http.post<any>(endpoint + 'disableRenter', Renter, httpOptions).pipe(
    //   tap((response) => {
    //     //console.log(`Renter delete response=${response}`)
    //   }),
    //   catchError(this.handleError<any>('error Renter deleted'))
    // );
  }

  //#endregion

  //#region Services
  getUserService(idRenter): Observable<any> {
    let resp = new Response('getRenterOffices');
    return this.http.get<any>(endpoint + 'userService?idRenter=' + idRenter).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renter Offices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  getUserServiceTypes(idRenter): Observable<any> {

    let resp = new Response('userServices');
    return this.http.get<any>(endpoint + 'userServiceTypes?idRenter=' + idRenter).pipe(
      tap((response) => {
        resp = response;
      }),
      catchError(this.handleError(resp))
    );
  }
  getServices(idRenterOffice): Observable<any> {

    let resp = new Response('OfficeService');
    return this.http.get<any>(endpoint + 'OfficeServices?idRenterOffice=' + idRenterOffice).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getUserServices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }


  getService(servId,servTypeId,idRenter): Observable<any> {

    let resp = new Response('getService');
    return this.http.get<any>(endpoint + 'getService?servId=' + servId + '&servTypeId='+ servTypeId + '&idRenter='+ idRenter).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getUserServices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  SaveService(service): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('saveService');
    return this.http.post<any>(endpoint + 'saveService', service,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveImageService(file): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    // //console.log(`Ready To upload=${user}`)
    let resp = new Response('SaveImageService');
    return this.http.post<any>(endpoint + 'saveImageService', file, httpMultipartOptions).pipe(
      tap((Renter) => {
        resp = Renter;
        //console.log(`saveImageService saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DeleteService(service): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'disableService?servId=' + service.Id ).pipe(
      tap((response) => {
        resp = service;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DuplicateService(idService): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'duplicateService?servId=' + idService ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DeleteDate(IdDate): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.post<any>(endpoint + 'deleteDate?IdDate=' + IdDate, httpOptions ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveDate(serviceDate): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    // //console.log(`Ready To upload=${user}`)
    let resp = new Response('saveDateService');
    return this.http.post<any>(endpoint + 'saveDateService', serviceDate, httpOptions).pipe(
      tap((Service) => {
        resp = Service;
        //console.log(`saveDateService saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  ////#endregion
  //#region cars

  getCars(idRenter): Observable<any> {

    let resp = new Response('userCars');
    return this.http.get<any>(endpoint + 'userCars?idRenter=' + idRenter).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getUserServices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  getCar(carId): Observable<any> {

    let resp = new Response('getCar');
    return this.http.get<any>(endpoint + 'getCar?carId=' + carId).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getCar received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  SaveCar(car): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(car)}`)
    let resp = new Response('saveCar');
    return this.http.post<any>(endpoint + 'saveCar', car,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Car saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveImageCar(file): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    // //console.log(`Ready To upload=${user}`)
    let resp = new Response('saveImageCar');
    return this.http.post<any>(endpoint + 'saveImageCar', file, httpMultipartOptions).pipe(
      tap((Renter) => {
        resp = Renter;
        //console.log(`saveImageCar saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveCertificateCar(file): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    // //console.log(`Ready To upload=${user}`)
    let resp = new Response('saveCertificateCar');
    return this.http.post<any>(endpoint + 'saveCertificateCar', file, httpMultipartOptions).pipe(
      tap((Renter) => {
        resp = Renter;
        //console.log(`saveCertificateCar saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DeleteCar(car): Observable<Response> {
    let resp = new Response('disableCar');
    return this.http.get<any>(endpoint + 'disableCar?carId=' + car.Id ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`DeleteCar disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  //#endregion

  //#region orders
  getOrders(pageNumber, pageSize,search,IsAdmin, IdRenter, idMonth): Observable<any> {
    let resp = new Response('getOrders');
    return this.http.get<any>(endpoint + 'orders?pageNumber=' + pageNumber + '&pageSize=' + pageSize+ '&search=' + search+ '&isadmin=' + IsAdmin + '&idrenter=' + IdRenter + '&idMonth=' + idMonth).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renters received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  getOrder(idCart,IdRenter, isAdmin): Observable<any> {
    let resp = new Response('getOrder');
    return this.http.get<any>(endpoint + 'getOrder?IdCart=' + idCart + '&IdRenter='+ IdRenter + '&isAdmin='+ isAdmin).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renters received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  getOrdersParameters(isadmin,idmonth,idrenter): Observable<any> {
    let resp =new Response('getOrdersParameters'); //+ '&dateSelected=' + date.toString()
    return this.http.get<any>(endpoint + 'getOrdersParameters?isadmin=' + isadmin + '&idmonth=' + idmonth + '&idrenter=' + idrenter).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renters received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveOrder(order): Observable<any> {
      ////console.log(`Save token=${JSON.stringify(user)}`
      //console.log(`Ready To upload=${order}`);
      let resp = new Response('SaveOrder');
      return this.http.post<any>(endpoint + 'saveorder', order, httpOptions).pipe(
          tap((response) => {
              resp =response;
              //console.log(`USER saved id=${JSON.stringify(resp)}`)
          }),
          catchError(this.handleError(resp))
      );
  }
  VerifyOrder(order): Observable<Response> {
    let resp = new Response('DeleteOrder');
    return this.http.get<any>(endpoint + 'verifyorder?Id=' + order.IdCart , httpOptions).pipe(
        tap((result) => {
          
            resp = result
            //console.log(`USER delete response=${JSON.stringify(resp)}`)
        }),
        catchError(this.handleError(resp))
    );

    
}
  //#endregion

  getMonths(): Observable<any> {
    let resp =new Response('getMonths'); //+ '&dateSelected=' + date.toString()
    return this.http.get<any>(endpoint + 'months').pipe(
      tap((response) => {
        resp = response;
        //console.log(`Renters received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  getAllNations(): Observable<any> {

    let resp = new Response('getAllNations');
    return this.http.get<any>(endpoint + 'getAllNations').pipe(
      tap((response) => {
        resp = response;
      }),
      catchError(this.handleError(resp))
    );
  }
  getThinRenters(): Observable<any> {

    let resp = new Response('getThinRenters');
    return this.http.get<any>(endpoint + 'getThinRenters').pipe(
      tap((response) => {
        resp = response;
      }),
      catchError(this.handleError(resp))
    );
  }
  sendEmail(email): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    let resp = new Response('SaveOrder');
    return this.http.post<any>(endpoint + 'sendEmail', email, httpOptions).pipe(
        tap((response) => {
            resp =response;
        }),
        catchError(this.handleError(resp))
    );
}
  //#region others
  getWidgetImages(): Observable<any> {
    let resp = new Response('getWidgetImages');
    return this.http.get(endpoint + 'getwidgetimages?IdUser=' + UserLogged.Id).pipe(
      tap((widgets) => {
        //console.log(`USERS received ${widgets}`)
      }),
      catchError(this.handleError(resp))
    );
  }


    DownloadFile(ItemFile): any {
      var contentType = '';
      var splitted = ItemFile.NomeFile.split(".", 2);
      var extension = splitted[1];
        var blob = null;
        if (extension.toLowerCase() == "pdf") {
            var byteArray = new Uint8Array(ItemFile.Data);
            var test = this.b64toBlob(ItemFile.Data, 'application/pdf');
            blob = new Blob([test], { type: 'application/pdf' });
            const element = document.createElement('a');
            element.href = URL.createObjectURL(blob);
            element.download = ItemFile.NomeFile;
            document.body.appendChild(element);
            element.click();
       // element.click();
        }
        else {
            contentType = "application/octet-stream"
            blob = this.b64toBlob(ItemFile.Data, contentType);
            saveAs(blob, ItemFile.NomeFile);
        }
        
       
        
       // return true;
        //window.open(url);
        //return this.http.get<any>(endpoint + 'downloadfile?IdFile=' + idItemFile).pipe(
        //    tap((result) => {
        //       // //console.log(`File download response=${result}`)
        //    }),
        //    catchError(this.handleError<any>('error file download'))
        //);
    }
     b64toBlob(b64Data, contentType):any {
            contentType = contentType || '';
            var sliceSize =  512; // sliceSize represent the bytes to be process in each batch(loop), 512 bytes seems to be the ideal slice size for the performance wise 

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
     }

     getWeaterData(city): Observable<any> {
      let resp = new Response('getWidgetImages');
      return this.http.get<any>("https://api.openweathermap.org/data/2.5/forecast?q=" + city + "&appid=ea35b11e5e851139dc5ec76cafa438ab&lang=it&units=metric").pipe(
        tap((response) => {
          resp =response;
          //console.log(`USERS received ${widgets}`)
        }),
        catchError(this.handleError(resp))
      );
    }

  //#endregion
  
 

  getPriceMasters(idRenter,idServiceType): Observable<any> {

    let resp = new Response('pricelists');
    return this.http.get<any>(endpoint + 'pricemaster?idRenter=' + idRenter + '&idServiceType=' + idServiceType).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getUserServices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  getPriceList(idPriceMaster): Observable<any> {

    let resp = new Response('pricelists');
    return this.http.get<any>(endpoint + 'pricelist?idPriceMaster=' + idPriceMaster).pipe(
      tap((response) => {
        resp = response;
        //console.log(`getUserServices received ${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }

  SavePriceList(priceList): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('savePriceList');
    return this.http.post<any>(endpoint + 'savePriceList', priceList,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SavePriceMaster(master): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('savePriceMaster');
    return this.http.post<any>(endpoint + 'savePriceMaster', master,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DisablePriceMaster(IdPriceMaster): Observable<Response> {
    let response = new Response('DisablePriceMaster');
    return this.http.get<any>(endpoint + 'disablePriceMaster?idPriceMaster=' + IdPriceMaster, httpOptions ).pipe(
      tap((resp) => {
        response = resp;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(response))
    );
  }
  DuplicaPriceMaster(IdPriceMaster): Observable<Response> {
    let resp = new Response('duplicaPriceMaster');
    return this.http.get<any>(endpoint + 'duplicaPriceMaster?idPriceMaster=' + IdPriceMaster, httpOptions ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveDiscount(discount): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('saveDiscount');
    return this.http.post<any>(endpoint + 'saveDiscount', discount,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DisableDiscount(IdDiscount): Observable<Response> {
    let resp = new Response('duplicaPriceMaster');
    return this.http.get<any>(endpoint + 'disableDiscount?IdDiscount=' + IdDiscount, httpOptions ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
    
  }
  SaveDisclosure(disclosure): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('saveDisclosure');
    return this.http.post<any>(endpoint + 'saveDisclosure', disclosure,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  DisableDisclosure(IdDisclosure): Observable<Response> {
    let resp = new Response('disableDisclosure');
    return this.http.get<any>(endpoint + 'disableDisclosure?IdDisclosure=' + IdDisclosure, httpOptions ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
    
  }
  DeleteServiceToPrice(IdServiceToPrice): Observable<Response> {
    let resp = new Response('DeleteRenter');
    return this.http.get<any>(endpoint + 'disableServiceToPrice?servId=' + IdServiceToPrice ).pipe(
      tap((response) => {
        resp = response;
        //console.log(`Services disabled id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  SaveServiceToPrice(serviceToPrice): Observable<any> {
    ////console.log(`Save token=${JSON.stringify(user)}`
    //console.log(`Ready To upload=${JSON.stringify(service)}`)
    let resp = new Response('saveService');
    return this.http.post<any>(endpoint + 'saveServiceToPrice', serviceToPrice,httpOptions).pipe(
      tap((serv) => {
        resp = serv;
        //console.log(`Service saved id=${JSON.stringify(resp)}`)
      }),
      catchError(this.handleError(resp))
    );
  }
  private handleError<T>(resp :Response, result?: Response) {
    return (error: any): Observable<Response> => {
        //alert('sono in handle error');
        // TODO: send the error to remote logging infrastructure
        //console.error(error); // log to //console instead
        resp.Error = error;
        resp.Data = null;
        // TODO: better job of transforming error for user consumption
        //console.log(`${resp.Operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(resp as Response); //of(result as T);
    };
  }
  
}
